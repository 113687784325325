@import "variables"

// POSITION - DISPLAY
//==========================================================>

@mixin displayFlex($alignItems: false, $justifyContent: false, $flexDirection: false)
  display: flex
  @if $alignItems
    align-items: $alignItems
  @if $justifyContent
    justify-content: $justifyContent
  @if $flexDirection
    flex-direction: $flexDirection

@mixin centralize
  display: flex
  align-items: center
  justify-content: center

@mixin positionAbsolute($top: false, $right: false, $bottom: false, $left: false)
  position: absolute
  @if $top
    top: $top
  @if $right
    right: $right
  @if $bottom
    bottom: $bottom
  @if $left
    left: $left

// SIZES
//==========================================================>

@mixin boxSize($width, $height)
  width: $width
  height: $height

@mixin square($dimension)
  width: $dimension
  height: $dimension

@mixin maxSize($width, $height)
  max-width: $width
  max-height: $height

@mixin minSize($width, $height)
  min-width: $width
  min-height: $height

@mixin fullWidthSize($height)
  width: 100%
  height: $height


// FONTS - TEXT
//==========================================================>

@mixin font($size: false, $color: false, $lineHeight: false, $fontWeight: false)
  @if $size
    font-size: $size
  @if $color
    color: $color
  @if $lineHeight
    line-height: $lineHeight
  @if $fontWeight
    font-weight: $fontWeight

@mixin fontSLH($size, $lineHeight)
  font-size: $size
  line-height: $lineHeight

@mixin truncate($width: false)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  @if $width
    width: $width


// GENERAL
//==========================================================>

@mixin svgSquare($size, $fill)
  width: $size
  height: $size
  fill: $fill

@mixin svg($width, $height, $fill)
  width: $width
  height: $height
  fill: $fill

@mixin closeBtnRight($top, $right)
  position: absolute
  top: $top
  right: $right
  cursor: pointer


// :PSEUDO / :BEFORE / :AFTER
//==========================================================>

@mixin beforeEmptySquare($size, $background: false, $top: false, $right: false, $bottom: false, $left: false)
  @include positionAbsolute($top, $right, $bottom, $left)
  content: ''
  width: $size
  height: $size
  @if $background
    background: $background

@mixin beforeWithContent($content, $color: false, $fontSize: false, $top: false, $right: false, $bottom: false, $left: false)
  @include positionAbsolute($top, $right, $bottom, $left)
  content: $content

  @if $color
    color: $color
  @if $fontSize
    font-size: $fontSize


// MARGINS
//==========================================================>

@mixin push--auto
  margin-left: auto
  margin-right: auto

@mixin fixedRight
  right: 16px
  @media (min-width: $mobile)
    right: calc((100vw - 540px) / 2)
  @media (min-width: $tablet)
    right: calc((100vw - 720px) / 2)
  @media (min-width: $desktop-md)
    right: calc((100vw - 960px) / 2)
  @media (min-width: $desktop)
    right: calc((100vw - 1184px) / 2)
