// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'

.login
  h1
    margin-bottom: 16px
    @media (min-width: $desktop)
      margin-bottom: 24px

  &__top-content
    display: none
    @media (min-width: $tablet)
      display: block
    &--mobile
      display: block
      @media (min-width: $tablet)
        display: none

  &__main-content-wrapper
    padding: 32px 0 48px
    background-color: $grey-bg
    @media (min-width: $tablet)
      padding: 48px 0
    @media (min-width: $desktop-md)
      padding: 64px 0

  &__main-content
    display: flex
    flex-direction: column-reverse
    @media (min-width: $tablet)
      flex-direction: row
    h2
      margin-bottom: 16px
    p
      margin-bottom: 32px


  &__subheader-last-paragraph p
    margin-bottom: 0

  &__header-paragraph
    margin-bottom: 24px
    @media (min-width: $tablet)
      margin-bottom: 32px
    @media (min-width: $desktop-md)
      margin-bottom: 56px !important

  &__media-blocks
    display: none
    margin-top: 64px
    @media (min-width: $tablet)
      display: flex
      flex-direction: column
    @media (min-width: $desktop-md)
      align-content: stretch
      flex-direction: row

  &__media-block
    display: flex
    border-radius: 4px
    margin-bottom: 16px
    overflow: hidden
    &:last-child
      margin-bottom: 0
    @media (min-width: $desktop-md)
      margin-bottom: 0
      flex-direction: column

  &__media-img-wrapper
    width: 40.72%
    overflow: hidden
    position: relative
    min-width: 40.72%
    display: flex
    justify-content: center
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    img
      @include boxSize(auto, 100%)
      min-width: 40.72%
      position: absolute
      top: 0
    &--first
      @media (min-width: $desktop)
        align-items: center
        img
          top: auto
    @media (min-width: $desktop-md)
      height: 207px
      width: 100%
      border-bottom-left-radius: 0
      border-top-right-radius: 4px
      img
        @include boxSize(100%, auto)
        min-height: 100%

  &__media-block-info
    padding: 16px
    border: 1px solid $bg-badge-rented-out
    border-left: none
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    @media (min-width: $desktop-md)
      padding: 24px 16px
      height: calc(100% - 207px)
      border-bottom-left-radius: 4px
      border-top-right-radius: 0
      border-top: none
      border-left: 1px solid $bg-badge-rented-out
    h2
      @include fontSLH(18px, 26px)
      margin-bottom: 16px
    p
      margin-bottom: 16px
      &:last-child
        margin-bottom: 0
    a
      @include fontSLH(16px, 24px)

  &__contacts
    padding: 64px 0
    h3
      margin-bottom: 8px

  &__contacts-wrapper
    background-color: $grey-bg
    border-radius: 4px
    padding: 32px 24px 24px
    @media (min-width: $tablet)
      @include displayFlex(center)
      padding: 48px 61px
    @media (min-width: $desktop-md)
      padding: 56px 81px
    @media (min-width: $desktop-md)
      padding: 56px 101px

  &__content-wrapper
    display: flex
    flex-direction: column
    @media (min-width: $tablet)
      margin-right: 16px
    @media (min-width: $desktop-md)
      margin-right: 37px
    @media (min-width: $desktop-md)
      margin-right: 111px
    h3
      @include fontSLH(16px, 24px)

  &__contacts-header
    font-size: 24px
    margin-bottom: 16px
    @media (min-width: $desktop-md)
      @include fontSLH(30px, 44px)

  &__contacts-paragraph
    margin-bottom: 24px
    @media (min-width: $desktop-md)
      margin-bottom: 32px

  &__contacts-info-link
    @include font(16px, $black, 24px)
    display: inline-flex
    align-items: center
    margin-bottom: 16px
    text-decoration: none
    &:hover
      color: $black
      span
        text-decoration: underline
    &:last-of-type
      margin-bottom: 24px
      @media (min-width: $desktop-md)
        margin-bottom: 32px
    img
      @include boxSize(18px, auto)
      margin-right: 10px

  &__working-hours
    display: flex
    div:first-child
      margin-right: 10px
    p
      margin-bottom: 0

  &__contacts-img
    display: none
    @media (min-width: $tablet)
      display: block
      img
        @include boxSize(234px, 165px)
    @media (min-width: $desktop-md)
      img
        @include boxSize(289px, 206px)
