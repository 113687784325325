@import '../variables'
@import '../mixins'


.b-button
  @include displayFlex(center, center)
  background-color: $orange
  border-radius: 6px
  border: none
  padding: 10px 20px
  color: white !important
  font-weight: 900
  text-align: center
  transition: background-color .1s ease
  //width: initial
  font-size: 16px
  svg
    fill: white
    margin-right: 10px

  @media (max-width: $screen-xs-max)
    width: 100%

  &:hover
    text-decoration: none
    cursor: pointer
    background-color: $orange-hover
  &:focus, &:active
    outline: none

  &--white
    background-color: white
    color: $orange !important
    border: 1px solid $orange
    transition: all .1s ease

    &:hover
      background-color: $orange
      color: white !important

  &--disabled
    background-color: $inactive

    &:hover
      cursor: initial
      background-color: $inactive

  &--huge
    padding: 15px
    max-width: 290px
    width: 290px

    @media (max-width: $screen-xs-max)
      width: 100%
      max-width: initial

  &--green
    background-color: $green

    &:hover
      background-color: $green-hover

  &--redirect
    position: relative
    padding-right: 30px

    &::after
      @include beforeEmptySquare(12px, transparent, 50%, 15px)
      transition: background-color .1s ease
      border: 3px solid white
      transform: rotate(45deg)
      margin-top: -6px
      border-left: none
      border-bottom: none
