// COLORS
$bg-badge-anonymous: #3130AF !default
$bg-badge-parsed: #0C5887 !default
$bg-badge-rented-out: #E0E2E2 !default
$bg-badge-new: #67A700 !default
$bg-badge-special-offer: #7B3395 !default
$bg-badge-parking: #1978be !default
$bg-charcoal: #313C3F !default
$bg-info-tabs: #E4F2D2 !default
$bg-location-search: #f8f8f8 !default
$black: #000 !default
$border-grey: #cecece !default
$border-form-input: #e8eaea !default
$border-form-correct: #77bd1f !default
$border-drop-down: #e7e7e7 !default
$border-light-grey: #f6f6f6 !default
$borders: #e0e2e2 !default
$dark: #000000 !default
$dark-backface: #a0a0a0 !default
$dark-bg: #000000 !default
$dark-borders: #5a6365 !default
$dark-hover: #474e50 !default
$dark-green: #668a8c !default
$dark-green-hover: #668a8c !default
$dark-grey: #afafaf !default
$dark-orange: #DB8C00 !default
$dark-quater-alpha: #bfbfbf !default
$dropdown-border: #e8e8e8 !default
$default: #000000 !default
$facebook: #3a589a
$google: #d93b2a
$green: #77bc1f !default
$green-hover: #539533 !default
$grey: #f3f4f5 !default
$grey-bg: #f4f5f5 !default
$grey-slightly-dark-bg: #F4F5F6 !default
$gull-grey-text: #A2A7A8 !default
$inactive: #cbcecf !default
$link-color: #739799 !default
$link-color-hover: #516d6f !default
$link-color-info-tabs: #729799 !default
$link-list-view-hover: #212529 !default
$light-grey-text: #a6abac !default
$light-green: #a7e45a !default
$linkedin: #1086bf !default
$link-water: #ced4da !default
$menu-text: $default !default
$orange: #ffa300 !default
$orange-hover: #d17c0a !default
$pale-green: #a0d062 !default
$pale-pale-green: #dcedc4 !default
$pale-green-bg: #f1f8e8 !default
$red: #f22708 !default
$twitter: #059ff5 !default
$warning: #e86060 !default
$white: #fff !default
$light-red: #FF5A5A  !default


// SIZES
$main-header-height: 50px
$detailed-view-header: 494px


// MEDIA
$screen-xxxs: 320px
$screen-xxs: 468px
$screen-xs: 480px
$screen-s-xs: 540px
$screen-s: 574px
$screen-sm: 768px
$screen-md: 960px
$screen-lg-md: 992px
$screen-lg: 1200px

$screen-xs-max: $screen-sm - 1
$screen-sm-max: $screen-md - 1
$screen-lg-md-max: $screen-lg-md - 1
$screen-md-max: $screen-lg - 1

$mobile: 576px
$tablet: 768px
$desktop-md: 992px
$desktop: 1200px

$from-mobile: 577px
$from-tablet: 769px
$from-desktop-md: 993px
$from-desktop: 1201px

// Extra small screen / phone
$screen-xs-min: $screen-xs

// Small screen / tablet
$screen-sm-min: $screen-sm

// Medium screen / desktop
$screen-md-min: $screen-md

// Large screen / wide desktop
$screen-lg-min: $screen-lg

// So media queries don't overlap when required, provide a maximum
$screen-xss-max: ($screen-xs-min - 1)
$screen-xs-max: ($screen-sm-min - 1)
$screen-sm-max: ($screen-md-min - 1)
$screen-md-max: ($screen-lg-min - 1)

$screen: 'only screen'
$xs-up: $screen
$xs-only: '#{$screen} and (max-width: #{$screen-xs-max})'

$sm-up: '#{$screen} and (min-width: #{$screen-sm-min})'
$sm-only: '#{$screen} and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max})'

$md-up: '#{$screen} and (min-width: #{$screen-md-max})'
$md-only: '#{$screen} and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})'

$lg-up: '#{$screen} and (min-width: #{$screen-lg-min})'
