@import "variables"
@import "mixins"


html
  overflow-x: hidden

@font-face
  font-display: swap

*
  -webkit-tap-highlight-color: transparent
  box-sizing: border-box

body
  -moz-osx-font-smoothing: grayscale
  -webkit-font-smoothing: antialiased
  font-smoothing: antialiased
  font-family: MuseoSans, sans-serif
  //margin: 0
  //font-size: 14px
  font-weight: 400
  line-height: 1.5
  color: $black
  text-align: left
  background-color: $white
  &.modal-open
    overflow: hidden
    height: 100vh
  &.hidden-scroll
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
      -webkit-appearance: none
      display: none
      width: 0 !important
      height: 0
      -webkit-overflow-scrolling: auto
    &::-webkit-scrollbar-thumb
      display: none
      width: 0 !important
      height: 0

@-moz-document url-prefix()
  body
    font-weight: normal !important

h1
  font-size: 24px
  line-height: 32px
  @media (min-width: $desktop-md)
    font-size: 30px
    line-height: 44px

h2
  @include fontSLH(21px, 32px)
  @media (min-width: $desktop-md)
    font-size: 24px

h3
  font-size: 18px
  line-height: 26px
  @media (max-width: $screen-sm)
    font-size: 16px
    line-height: 24px

h4,
h5,
h6
  font-family: 'MuseoSans', sans-serif
  font-weight: bold

.title
  &--1
    @extend h1
  &--2
    @extend h2
  &--3
    @extend h3
  &--4
    @extend h4
  &--5
    @extend h5
  &--6
    @extend h6

a
  text-decoration: none
  color: $green
  &:hover
    color: $green-hover

ul
  margin-top: 0

img
  vertical-align: middle

.form-group
  position: relative
  .form-control
    & + .validation-wrapper:before
      @include beforeWithContent('*', $green, 14px, 11px, 10px)

    &:focus + .validation-wrapper,
    &.is-valid + .validation-wrapper
      &:before
        color: $green

    &.error
      border-color: $red

    &.error + .validation-wrapper
      &:before
        color: $red

  .validation-message
    color: $red
    line-height: 1
    margin-top: 5px
    font-weight: 300
    &:first-letter
      text-transform: capitalize

.map-container,
.map-element
  height: $detailed-view-header


.d-n
  display: none !important

.d-b
  display: block !important

h1,
h2,
h3,
h4,
h5,
h6
  font-family: 'MuseoSans', sans-serif
  font-weight: bold !important
  margin-top: 0

svg
  overflow: hidden
  vertical-align: middle

p
  @include fontSLH(16px, 24px)
  margin-top: 0

[type=button],
[type=reset],
[type=submit],
button
  -webkit-appearance: button
  cursor: pointer

button, select
  text-transform: none

button, input
  overflow: visible

button, input, optgroup, select, textarea
  margin: 0
  font-family: inherit
  font-size: inherit
  line-height: inherit

input[type=checkbox], input[type=radio]
  box-sizing: border-box
  padding: 0

label
  display: inline-block

.scms-help-section-opening
  .clearfix
    display: -webkit-box
    display: -webkit-flex
    display: -ms-flexbox
    display: flex
    -webkit-flex-wrap: wrap
    -ms-flex-wrap: wrap
    flex-wrap: wrap
    margin-right: -15px
    margin-left: -15px
  table
    width: 100%

  .text-center .col-sm-4
    text-align: center
    @media (max-width: $screen-sm)
      img
        width: 30% !important

.clearfix
  &:before,
  &:after
    content: ' '
    display: table
    clear: both

.safari-fullscreen
  position: fixed
  overflow: hidden

.invisible
  @include square(0)
  opacity: 0
  margin: 0
  padding: 0
  position: absolute

// MODAL ==================================

.ReactModal__Overlay
  transition: opacity 500ms ease-in-out
  opacity: 0
  @media (max-width: $screen-sm)
    padding: 10px
    overflow: auto
  @media (min-width: $desktop-md)
    top: 72px

  &--after-open
    opacity: 1

  &--before-close
    opacity: 0

.ReactModal__Content
  @extend .modal-dialog
  transition: top 0.5s ease-in-out, opacity 0.5s ease
  transform: translateY(30px)
  //top: 40%
  opacity: 0
  margin: auto
  width: 100%
  max-width: 600px
  &--after-open
    opacity: 1
    //top: 50%
    transform: translateY(0)

  &--before-close
    opacity: 0
    //top: 40% !important
    transform: translateY(0px)

  &:focus
    outline: none !important

  @media (max-width: $screen-sm)
    &:not(.data-protection-modal),
    &:not(.search-options-modal)
      top: 0
      margin: 0 auto
      left: auto
      right: auto
      transform: none
      position: relative

.ReactModal__Body--open
  overflow: hidden

.modal-dialog .modal-content
  border: none

.modal-active
  overflow: hidden
  height: 100vh

.modal-header
  display: -webkit-box
  display: flex
  align-items: flex-start
  justify-content: space-between
  padding: 1rem
  border-bottom: 1px solid #dee2e6
  border-top-left-radius: calc(.3rem - 1px)
  border-top-right-radius: calc(.3rem - 1px)

.modal-body
  position: relative
  flex: 1 1 auto
  padding: 1rem

.modal-footer
  display: -webkit-box
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: flex-end
  padding: .75rem
  border-top: 1px solid #dee2e6
  border-bottom-right-radius: calc(.3rem - 1px)
  border-bottom-left-radius: calc(.3rem - 1px)
  & > *
    margin: .25rem

.visible
  visibility: visible !important

.hidden
  z-index: 99 !important

#common-icons-application,
#common-icons-detailed_view,
#common-icons-ep,
#common-icons-favorites_page,
#common-icons-forgot_password,
#common-icons-list_office,
#common-icons-list_view,
#common-icons-locations_comparison,
#common-icons-login,
#common-icons-provider,
#common-icons-search_agent_unavailable_info,
#common-icons-search_agents,
#common-icons-sign_up,
#common-icons-simple_cms,
#common-icons-recovery_password
  display: block !important
  height: 0
  width: 0
  overflow: hidden

