// MOBILE-FIRST
@import "../../src/stylesheets/variables"
@import '../../src/stylesheets/mixins'

.login-form
  background-color: $white
  border-radius: 4px
  box-shadow: 0 0 14px 0 rgba(222,231,231,0.55)
  padding: 24px 16px
  margin-bottom: 24px
  @media (min-width: $desktop-md)
    padding: 32px

  h2
    @media (min-width: $desktop-md)
      margin-bottom: 24px !important

  a
    @include font(16px, 24px)

  .form-group:not(.form-group--checkbox)
    .login-form__input
      @include fullWidthSize(52px)
      @include font(14px, $black, 1.428571429)
      font-family: MuseoSans, Helvetica, Arial, sans-serif
      //max-width: 470px
      border: 1px solid $borders
      background-color: $white
      border-radius: 4px
      box-shadow: none !important
      outline: none !important
      -webkit-appearance: none
      padding: 0 16px
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
      &::placeholder
        color: $gull-grey-text
      &.login-form__input-error
        border-color: $red
      input::-webkit-credentials-auto-fill-button
        visibility: hidden
        pointer-events: none
        position: absolute
        right: 0
      input::-webkit-contacts-auto-fill-button
        visibility: hidden
        display: none !important
        pointer-events: none
        position: absolute
        right: 0
      input::-webkit-textfield-decoration-container
        display: none !important

  &__input.active,
  &__input:focus
    padding-top: 15px !important

  &__floating-label
    @include positionAbsolute(15px, $left: 16px)
    font-size: 14px
    line-height: 24px
    color: $black
    transition: all ease-in-out 0.3s
    &.active
      font-size: 12px
      transform: translate(0, -12px)
      font-weight: 300

  &__label
    width: 100%
    margin: 0
    input:focus, input:-webkit-autofill
      padding-top: 15px !important
    input:focus, input:-webkit-autofill
      & ~ .login-form__floating-label
        font-size: 12px
        transform: translate(0, -12px) !important
        font-weight: 300

  .form-group
    margin-bottom: 8px

    &.login-form__form-group-error
      .login-form__input
        border-color: $red !important
      .login-form__input-tooltip-error
        display: block !important
      &:before
        color: $red

    &--submit
      margin-bottom: 16px
      @media (min-width: $tablet)
        margin-bottom: 24px

    &--checkbox
      display: flex
      align-items: center
      height: 21px
      margin-bottom: 32px
      padding-top: 5px
      margin-top: 16px
      @media (min-width: $tablet)
        justify-content: space-between
      &:before
        display: none
      input:checked ~ .checkmark
        background-color: $green
      .checkmark
        position: absolute
        top: 5px
        left: 0
        height: 16px
        width: 16px
        border: 1px solid $inactive
        border-radius: 2px
        background: $white
        &::after
          left: 5px
          top: 1px
          width: 5px
          height: 9px
          border: solid white
          border-width: 0 2px 2px 0
          -webkit-transform: rotate(45deg)
          transform: rotate(45deg)
          display: block
          content: ""
          position: absolute

  #remember_me
    height: 16px
    width: 1px
    position: absolute
    left: 3px
    top: 5px
    border: none

  .password-visibility
    @include positionAbsolute(16px, 22px)
    font-size: 18px
    fill: $gull-grey-text
    cursor: pointer

  &__remember-me-label
    @include fontSLH(16px, 24px)
    margin-left: 15px
    cursor: pointer
    user-select: none
    label
      margin: 0
      cursor: pointer
      margin-left: 8px
      display: flex
    &:hover
      .checkmark
        border: 1px solid $green

  &__input-tooltip
    @include font(12px, $black, 16px)
    margin-top: 8px

  &__input-tooltip-error
    @include font(12px, $red, 19px)
    display: none

  &__login-error-message
    @include font(12px, $red, 19px)
    margin-bottom: 8px

  &__forgot-password-link
    display: none
    @media (min-width: $tablet)
      display: inline-block
      margin-bottom: 0
    &--mobile
      display: inline-block
      margin-bottom: 16px
      @media (min-width: $tablet)
        display: none !important

  &__sign-up-link
    margin-bottom: 0 !important
    @media (min-width: $tablet)
      text-align: center
    a
      margin-left: 5px


  &::-webkit-credentials-auto-fill-button
    visibility: hidden
    pointer-events: none
    position: absolute
    right: 0


  input[type=password]::-webkit-contacts-auto-fill-button,
  input[type=password]::-webkit-credentials-auto-fill-button
    visibility: hidden
    display: none !important
    pointer-events: none
    height: 0
    width: 0
    margin: 0

  .b-button
    height: 48px
    text-transform: inherit
    width: 100%
    border-radius: 4px
